import React from 'react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/outline';


export function CallToAction({name}) {
    return (
        <Link to="/explore">
        <button className='bg-white rounded-xl w-full'>
            <div className="flex items-center text-center justify-center align-middle h-48 lg:h-96">
                <div className='' >
                    <PlusCircleIcon className='m-auto h-16 w-16'/>
                    <div className='text-2xl pl-4 pr-4'>Add {name}</div>
                    <div className='lowercase'>click here to add your first {name}</div>
                </div>
            </div>
        </button>
        </Link>
    )
}
