import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './App.css';
import {useMapSettings} from "./hooks/use-map-settings";
import { RegisterPage } from "./pages/auth/register";
import { ResetPassword} from "./pages/auth/reset-password";
import { LoginPage} from "./pages/auth/sign-in";
import {ForgotPassword} from "./pages/auth/forgot-password";
import {MapContext, MapPopupContext} from "./contexts/map-context";
import {useMapPopup} from "./hooks/map-popup-hook";
import FallBackPage from "./pages/fallBackPage";
import ScrollToTop from "./components/scroll-to-top";
import {refreshTokenTimer, clearTokenExpirationTimer} from "./components/http-common";
import ProtectedRoute from "./components/ProtectedRoute";
import {ChangePasswordPage} from "./pages/auth/change-password";
import GoogleAnalytics from "./components/google-analytics";
import DashboardPage from "./pages/dashboard";
import PrivacyPolicyPage from "./pages/privacy-policy";
import TermsAndConditionsPage from "./pages/terms-and-conditions";
import ContactUsPage from "./pages/contact";
import setupLocatorUI from "@locator/runtime";
import {Helmet} from "react-helmet";
import {ThemeProvider} from "./contexts/theme-context";

const LocationDetailPage = lazy(() => import("./pages/location-detail-page"));
const Home = lazy(() => import('./pages/home'));
const ExplorePage = lazy(() => import('./pages/explore'));
const ProductRecommendations = lazy(() => import('./pages/product-recommendations'));
const UpdatePage = lazy(() => import('./pages/add-activity-page'));

const queryClient = new QueryClient();




function App() {

    if (process.env.NODE_ENV === "development") {
        setupLocatorUI();
    }

    const mapSettings = useMapSettings();
    const mapPopup = useMapPopup();

    clearTokenExpirationTimer()
    refreshTokenTimer()

    return (

        <>
        <Helmet>
            <title>Adventuresum</title>
            <meta name="description" content="The World is waiting." />
        </Helmet>

        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
            <MapContext.Provider value={mapSettings}>
            <MapPopupContext.Provider value={mapPopup}>
            <Router>
                <GoogleAnalytics />
                <ScrollToTop />
                <Suspense fallback={<FallBackPage/>}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/explore/*' element={<ExplorePage mapSettings={mapSettings}/>} />
                        <Route exact path={'/trails/add'} element={<ProtectedRoute><UpdatePage /></ProtectedRoute>} />
                        <Route exact path={'/climbing/add'} element={<ProtectedRoute><UpdatePage /></ProtectedRoute>} />
                        <Route path={'/trails/:slug/edit'} element={<UpdatePage />} />
                        <Route path={'/climbing/:slug/edit'} element={<UpdatePage />} />
                        <Route path='/:activityType/:slug/*' element={<LocationDetailPage/>} />
                        <Route path='/register/' element={<RegisterPage mapSettings={mapSettings} />} />
                        <Route path='/login/' element={<LoginPage mapSettings={mapSettings} />} />
                        <Route path={'/settings/*'} element={<ProtectedRoute><ChangePasswordPage/></ProtectedRoute>} />
                        <Route path='/password-reset/confirm/:uid/:token/' element={<ResetPassword mapSettings={mapSettings} />} />
                        <Route path='/forgot-password/' element={<ForgotPassword mapSettings={mapSettings} />} />
                        <Route path='/product-recommendations/:activity/:name/:itemID' element={<ProductRecommendations />} />
                        <Route path={'/fallback'} element={<FallBackPage />} />
                        <Route path={'/dashboard'} element={<ProtectedRoute><DashboardPage mapSettings={mapSettings}  /></ProtectedRoute>} />
                        <Route path={"/privacy-policy"} element={<PrivacyPolicyPage/>} />
                        <Route path={"/terms-and-conditions"} element={<TermsAndConditionsPage/>} />
                        <Route path={"/contact"} element={<ContactUsPage/>} />
                    </Routes>
                </Suspense>
            </Router>
            </MapPopupContext.Provider>
            </MapContext.Provider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        </>
    );
}

export default App;
