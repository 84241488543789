import {useState} from "react";

export const useMapPopup = () => {
    const [activityType, setActivityType] = useState(null)
    const [activityName, setActivityName] = useState(null)
    const [activityLocation, setActivityLocation] = useState(null)
    const [isVisible, setIsVisible] = useState(false)

    return {
        activityType,
        setActivityType,
        activityName,
        setActivityName,
        activityLocation,
        setActivityLocation,
        isVisible,
        setIsVisible
    }
}