import Nav from "../components/nav";
import React, {useState} from "react";
import Footer from "../components/footer";

import {EnabledActivities} from "../components/enabled-activities";
import {EnabledStyles} from "../components/enabled-styles";
import {useTheme} from "../contexts/theme-context";
import DashboardBaseMap from "../components/maps/dashboard-base-map";
import { FavoriteCounter } from "../components/favorite-counter";
import { CommentCounter } from "../components/comment-counter";
import {EnabledDashboardSections} from "../components/enabled-dashboard-sections";
import { DashboardDetailSections } from "../components/dashboard-detail-section";
import {PhotoCounter} from "../components/photo-counter";

const DashboardPage = () => {

    
    const {currentTheme} = useTheme()

    return (
        <>
            <div className={""}>
                <Nav></Nav>

                <div className={"mt-8 pl-8 pr-8"}>
                    <div className={`rounded-lg mt-4 pb-4 grid grid-cols-3 lg:grid-cols-3 ${currentTheme.bg.card}`}>
                            <FavoriteCounter></FavoriteCounter>
                            <PhotoCounter></PhotoCounter>
                            <CommentCounter></CommentCounter>
                    </div>
                    <div className={"relative rounded-lg h-[30rem] w-full mt-4 "}>

                        <DashboardBaseMap></DashboardBaseMap>
                    </div>
                    <div className={`w-full rounded-lg shadow-2xl mt-4 mb-4 ${currentTheme.bg.card}`}>
                        <div className="pb-4 grid grid-cols-1 lg:grid-cols-2 gap-1">
                            <EnabledActivities/>
                            <EnabledStyles/>
                        </div>
                    </div>
                    <DashboardDetailSections/>
                  
                </div>
                <Footer></Footer>
            </div>
        </>

    )
}

export default DashboardPage;