import React, { useState, useEffect } from 'react'
import http from './http-common'
import { useTheme } from '../contexts/theme-context'
import { CallToAction } from './call-to-action'

const DashboardComments = (props) => {
    const [comments, setComments] = useState([])
    const [pagination, setPagination] = useState({ next: null, previous: null });
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const {currentTheme} = useTheme()

    const fetchFavorites = async (url) => {
        try {
            const endpoint = url || `/${props.activity_type}/reviews/?me=True`;
            const res = await http.get(endpoint)
            setComments(res.data.results)
            setPagination({ next: res.data.next, previous: res.data.previous })
            setIsLoading(false)
        } catch (error) {
            setIsError(true)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFavorites()
    }, [])

    return (
        <>
            <div className={`border rounded-2xl p-4 mb-4 ${currentTheme.bg.card}`}>
                
                    <div className="capitalize">
                        <div className={"font-bold mb-2 text-xl"}>
                            {props.title}
                        </div>

                        {!isLoading && comments.length > 0 && (

                        <div className={"flex flex-wrap"}>
                            {comments.length > 0 && comments.map((comment) => (
                                <div key={comment.id} className="w-full pb-2 pr-2">
                                    <button className={"w-full"} onClick={() => { window.location = `/${props.activity_type}/${comment?.activity.slug}/` }}>
                                        <div className="card bg-base-100 shadow-xl">
                                            <div className="card-body">
                                                <h2 className="card-title">{comment?.activity.name}</h2>
                                                <p className="text-left">{comment?.review}</p>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>

                        )}

                        
                        <div className="pagination flex justify-between mt-2">
                            {pagination.previous && <button className={"btn btn-sm btn-outline bg-white"} onClick={() => fetchFavorites(pagination.previous)}>Previous</button>}
                            {pagination.next && <button className={'btn btn-sm btn-outline bg-white'} onClick={() => fetchFavorites(pagination.next)}>Next</button>}
                        </div>
                    </div>
                

                { !isLoading && comments.length === 0 && (
                    <CallToAction name={"Comments"} />
                ) }

            </div>
        </>
    )
}

export default DashboardComments;