import React, {Fragment, useEffect} from 'react';
import {useLogin} from "../hooks/use-login";
import {Link} from "react-router-dom";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import logo_icon from "./logo-icon.png";
import {classNames} from "./header/logged-in-panel";
import CommandKSearch from "./search/command-k-search";
import Tippy from "@tippyjs/react";
import {CURRENT_PAGE_URL} from "../pages/auth/redirect-to-last-page";
import {useTheme} from "../contexts/theme-context";

const Nav = () => {
    sessionStorage.setItem(CURRENT_PAGE_URL, window.location.pathname)    // set the current page url in local storage using the CURRENT_PAGE_URL key
    const pathArray = window.location.pathname.split('/')
    const navListStyle = "md:text-md text-white hover:text-slate-300 inline-flex items-center px-1 pt-1 font-medium"
    const {currentTheme} = useTheme()

    let isHomePage = false
    let loginHook = useLogin()
    const [isSearchVisible, setIsSearchVisible] = React.useState(false)

    // is is the home page if [1] is an empty string
    // is is the home page if [1] is an empty string
    if (pathArray[1] === "") {
        isHomePage = true
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            // For Mac

            const isMac = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

            if (isMac && event.metaKey && event.key === 'k') {
                setIsSearchVisible(!isSearchVisible);
                event.preventDefault(); // Prevents the default browser behavior
            }
            // For Windows
            else if (!isMac && event.ctrlKey && event.key === 'k') {
                setIsSearchVisible(!isSearchVisible);
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Disclosure as="nav" className={ isHomePage ? "" : `${currentTheme.bg.primary}` }>
            {({ open }) => (
                <>
                    <div className="relative sm:h-16 md:h-20 md:justify-between w-full mx-auto">
                        <div className="flex justify-between sm:h-16 md:h-20">
                            <div className=" flex my-auto md:ml-6 w-screen md:mr-6">
                                <div className="mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className={`inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 hover focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}>
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className={`block h-6 w-6 ${currentTheme.text.light} `} aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className={`block ${currentTheme.text.light} rounded h-6 w-6`} aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>

                                { !isHomePage &&
                                <div className="flex items-center">
                                    {/* do not display logo on home page */}
                                    <a href="/">
                                        <img className="invisible md:visible absolute top-2 sm:left-4 sm:w-14" src={logo_icon} alt="Adventuresum" />
                                    </a>
                                </div>
                                }
                                <div className="invisible w-full sm:visible flex-shrink-0 items-center text-center justify-center md:h-20 text-center md:flex sm:space-x-8 lg:space-x-44 md:space-x-24 whitespace-pre">
                                    <Link key={"explore-nav-button"} to={"/explore/"} id={"explore-button"} className={navListStyle}>Explore</Link>
                                    <a id="store-button" key={"store-nav-button"} href="https://store.adventuresum.com" className={navListStyle}>Store</a>
                                    <Menu as="div" className="relative z-10 inline-flex">
                                        <div>
                                            <Menu.Button className="flex">
                                                <span className="sr-only">Contribute</span>
                                                <div className={navListStyle}>Add Activity</div>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="origin-top-right absolute top-7 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <div className={"relative"}>
                                                        <a href="/trails/add" className={classNames(active ? 'bg-gray-100 relative' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                                            New Trail
                                                        </a>
                                                            { !loginHook.isLoggedIn && (
                                                                <Link className={"hover:text-slate-300"} to={"/login"}>
                                                                <button>
                                                                <Tippy placement={"right"} content="Log in to Contribute">
                                                                    <div id={"not-logged-in-overlay"} className="overlay absolute inset-0 bg-white bg-opacity-50"></div>
                                                                </Tippy>
                                                                </button>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <div className={"relative"}>
                                                            <a href="/climbing/add" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}>
                                                                New Climbing Route
                                                            </a>
                                                            { !loginHook.isLoggedIn && (
                                                                <Link className={"hover:text-slate-300"} to={"/login"}>
                                                                <button>
                                                                <Tippy placement={"right"} content="Log in to Contribute">
                                                                    <div id={"not-logged-in-overlay"} className="overlay absolute inset-0 bg-white bg-opacity-50"></div>
                                                                </Tippy>
                                                                </button>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>

                                        </Transition>
                                    </Menu>
                                    { loginHook.isLoggedIn ? (
                                            <>




                                                <Menu as="div" className="relative z-10 inline-flex">
                                                    <div>
                                                        <Menu.Button className="flex">
                                                            <span className="sr-only">Open user menu</span>
                                                            <div className={navListStyle}>My Account</div>
                                                            {/*<img*/}
                                                            {/*    className="h-8 w-8 rounded-full"*/}
                                                            {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                                                            {/*    alt=""*/}
                                                            {/*/>*/}
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >

                                                        <Menu.Items
                                                            className="origin-top-right absolute top-7 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <Link to="/settings">
                                                                    <div
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                        )}
                                                                    >Settings
                                                                    </div>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <Link to="/dashboard">
                                                                    <div
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        Dashboard
                                                                    </div>
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <div
                                                                        onClick={ handleLogout }
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                        )}
                                                                    >
                                                                        Sign out
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                            </>) : <><div className={"hover:text-white " + navListStyle}><Link className={"hover:text-slate-300"} to={"/login"}>Login</Link><span className={"whitespace-pre"}> / </span> <Link className={"hover:text-slate-300"} to={"/register"}>Signup</Link></div></> }
                                </div>
                                { !isHomePage &&
                                <div onClick={() => {setIsSearchVisible(true)}}>
                                    <svg className="absolute right-2 top-2 h-6 w-6 sm:h-8 sm:w-8 sm:top-7 sm:right-4 text-white hover:cursor-pointer"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <CommandKSearch isVisible={isSearchVisible} setIsVisible={setIsSearchVisible}></CommandKSearch>
                                </div>
                                }
                            </div>
                            <div className="visible absolute top-1 right-1/2 transform translate-x-1/2 sm:hidden md:hidden text-center md:flex">
                                <Link key={"explore-nav-button"} to={"/explore/"} id={"explore-button"} className={navListStyle}>Explore</Link>
                            </div>

                        </div>
                    </div>
                    {/* Mobile menu, show/hide based on menu state. */}

                    <Disclosure.Panel className="visible sm:hidden">
                        <div className="pt-2 pb-3 space-y-1 z-10">
                            <Disclosure.Button
                                as="div">
                                <Link to={"/"} className="hover:text-black border-transparent text-white hover:bg-gray-50 hover:border-gray-300  block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6">Home</Link>
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="https://store.adventuresum.com"
                                className="hover:text-black border-transparent text-white hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                            >
                                Store
                            </Disclosure.Button>
                            { loginHook.isLoggedIn ? (
                                <>
                                <Disclosure.Button
                                    as="div"
                                    className="hover:text-black block px-4 py-2 text-base font-medium text-white  hover:bg-gray-100 sm:px-6"
                                >
                                    <Link to="/settings">Settings</Link>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="div"
                                    className="hover:text-black block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"
                                >
                                    <Link to="/trails/add">Add a New Trail</Link>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="div"
                                    className="hover:text-black block px-4 py-2 text-base font-medium text-white  hover:bg-gray-100 sm:px-6"
                                >
                                    <Link to="/climbing/add">Add a Climbing Route</Link>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="div"
                                    className="hover:text-black block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"
                                >
                                    <Link to="/dashboard">Dashboard</Link>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="div"
                                    onClick={handleLogout}
                                    className="hover:text-black block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"
                                >
                                    Sign out
                                </Disclosure.Button>
                                </>) :
                                <>
                                <Disclosure.Button
                                    as="div">
                                    <Link to={"/register"} className="hover:text-black border-transparent text-white hover:bg-gray-50 hover:border-gray-300  block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6">Signup</Link>

                                </Disclosure.Button>
                                    <Disclosure.Button
                                        as="div">
                                        <Link className={"hover:text-black border-transparent text-white hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"} to={"/login"}>Login</Link>
                                    </Disclosure.Button>


                                </>
                            }


                        </div>

                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

function handleLogout() {
    console.log("logged out")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("access_token")
    localStorage.setItem("is_logged_in", false)
    window.location.replace(window.location)
}

export default Nav