import React, { useState, useEffect, useRef } from 'react';
import http from './http-common';
import MapThumbnail from './map-thumbnail';
import { useTheme } from '../contexts/theme-context'
import {CallToAction} from './call-to-action';

const DashboardFavorites = (props) => {
    const [favorites, setFavorites] = useState([]);
    const [displayedFavorites, setDisplayedFavorites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [displayIndex, setDisplayIndex] = useState(0); // Index to track the chunk of displayed favorites
    const pageSize = 10; // Number of favorites to show at once in the UI
    const {currentTheme} = useTheme()


    const containerRef = useRef(null);

    const fetchFavorites = async (page) => {
        try {
            const endpoint = `/${props.activity_type}/user-favorites/?page=${page}&page_size=9`;
            const res = await http.get(endpoint);
            setFavorites(res.data.results);
            setDisplayedFavorites(res.data.results.slice(0, pageSize));
            setCurrentPage(page);
            setTotalPages(Math.ceil(res.data.count / res.data.results.length));
            setIsLoading(false);
            setDisplayIndex(0); // Reset display index on new page load
        } catch (error) {
            setIsError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFavorites(currentPage);
    }, []);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            fetchFavorites(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            fetchFavorites(currentPage + 1);
        }
    };

    return (
        <div ref={containerRef} className={`border rounded-2xl p-4 mb-4 ${currentTheme.bg.card}`}>
            <div className="flex justify-between items-center mb-2">
                <h1 className="font-bold text-lg">{props.title}</h1>
                
                {displayedFavorites.length > 0 &&

                    <div>
                        <button onClick={handlePrevClick} disabled={currentPage <= 1}>{'<'}</button>
                        <span>{`${currentPage} of ${totalPages}`}</span>
                        <button onClick={handleNextClick} disabled={currentPage >= totalPages}>{'>'}</button>
                    </div>
            }
            </div>
            {isLoading &&
                <div className="animate-pulse h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-full"></div> 
            }

            {!isLoading && displayedFavorites.length > 0 &&
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                    {displayedFavorites.map((favorite) => (
                        <div key={favorite.id} className="pb-2 pr-2">
                            <button className={"w-full max-w-[24rem]"} onClick={() => { window.location = `/${props.activity_type}/${favorite?.activity.slug}/`; }}>
                                <div className="bg-white rounded-xl shadow-xl">
                                    <MapThumbnail url={favorite?.activity.map_thumbnail} />
                                    <div className='pl-2'>
                                        <h2 className="font-bold text-left">{favorite?.activity.name}</h2>
                                        <p className="text-left">{favorite?.activity.location}</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                    
                    
            
                </div>
            }

            {!isLoading && displayedFavorites.length === 0 &&
                <CallToAction name={props.title}></CallToAction>
            }
        </div>
    );
};

export default DashboardFavorites;
