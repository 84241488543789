import {useNavigate} from "react-router-dom";
import {XIcon} from "@heroicons/react/outline";
import React from "react";

const CloseToLastPage = () => {
    const navigate = useNavigate()

    const handleClose = () => {

        let redirect = "/"
        // from session storage, get the current page
        if (sessionStorage.getItem("current_page_url")) {
            redirect = sessionStorage.getItem("current_page_url")
        }
        window.location.assign(redirect)

    }

    return (
        <XIcon onClick={handleClose}
               className={"absolute hover:rounded-full hover:bg-gray-300 hover:cursor-pointer right-0 fill-blue-600 m-2 inset-y-0 h-8"}></XIcon>
    )
}

export default CloseToLastPage;