

export default function activityNameToInt(activityName) {

    if (activityName.includes("trail")) {
        return 1;
    }
    if (activityName.includes("campsite")) {
        return 2;
    }
    if (activityName.includes("campground")) {
        return 3;
    }
    if (activityName.includes("climbing")) {
        return 4;
    }

    console.log("activityNameToInt: activityName not found: ", activityName);
    return 0;
}
