import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fontsource/poppins"

import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// get ga from the window object
const ga = window.ga;

if (process.env.NODE_ENV === "production") {
    LogRocket.init('bspwyf/adventuresum');
    setupLogRocketReact(LogRocket);

    LogRocket.getSessionURL(function (sessionURL) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'LogRocket',
            eventAction: sessionURL,
        });
    });

    Sentry.init({
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
            scope.setExtra("sessionURL", sessionURL);
        });
    });
}





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals;
