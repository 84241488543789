import { Link } from 'react-router-dom';
import React, {useState} from "react";
import http from "../../components/http-common";

export const ForgotPassword = () => {

    // create a json object for uid and token and new password
    const forgotPasswordData = {
        email: "",
    }

    const [email, setEmail] = useState('')

    // setPassword should set resetPassword.new_password
    const handleSetEmail = (event) => {
        setEmail(event.target.value)
        setEmailResetMessage('')
    }

    const [emailResetMessage, setEmailResetMessage] = useState('')

    const handleSubmit = async () => {
        forgotPasswordData.email = email
        const res = await http.post('/auth/users/reset_password/', forgotPasswordData);

        // if res?.status === 204 set emailResetMessage to "Email sent"
        // else set emailResetMessage to "Email not sent"
        if (res?.status === 204) {
            setEmailResetMessage("Email sent")
            setEmail('')
        }
        else {
            setEmailResetMessage("Something happened. Email not sent. Try again")
        }
    }

    return (
        <div className="bg-cover bg-center bg-hero-pattern flex min-h-full flex-col justify-center w-full h-screen">
            <div className={"flex flex-col justify-center items-center  backdrop-blur-lg h-screen w-full"}>


                <div className="mt-16 w-full sm:w-full sm:max-w-xl">
                    <div className="filter-none bg-white pb-8 px-4 shadow sm:rounded-md md:px-10">
                        {/* create link to the forgot password page */}
                        <h2 className="pt-4 text-center text-xl tracking-wider">Forgot Password?</h2>

                        <div className="mx-auto mt-8 md:max-w-sm space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={handleSetEmail}
                                        value={email}
                                        autoComplete="email"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            { emailResetMessage !== "" ?
                                <div className={"mt-4 text-black text-sm text-center"}>
                                    <ul>
                                        <li>{emailResetMessage}</li>
                                    </ul>
                                </div>
                                : <></>
                            }



                            <div className={ "flex flex-col items-center" }>
                                <button
                                    onClick={handleSubmit}
                                    className="flex w-full w-1/3 items-center justify-center rounded-md border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Submit
                                </button>
                                <div className={"pt-1 text-sm"}>Back to <Link className={"underline text-blue-600 hover:text-blue-800"} to={"/login/"}>Sign In</Link></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )}