import React from "react";
import spinner from './spinner.svg'

export default function LoadingSpinner(props) {

    return (
        <div className="animate-spin flex flex-col">
            <div className="flex flex-row justify-center items-center">
                <img src={spinner} className={`opacity-40 h-${props.size} w-${props.size}`} viewBox="0 0 24 24"/>
            </div>
        </div>
    )
}