
class Coordinates {
    constructor(lat=null, lon=null) {
        this.lat = lat;
        this.lon = lon;
        this.zoom = 10;
        this.shouldShowCoords = false;
        this.hasApprovedPermissions = false;
    }
}

export function parseURLLatLong() {
    const path = window.location.pathname.split('/');
    if (path[1] && path[1] === "explore" && path[2]) {
        const [lat, lon, zoom] = path[2].split(',');

        const coordinates = new Coordinates(lat ? parseFloat(lat) : null, lon ? parseFloat(lon) : null);
        coordinates.shouldShowCoords = true;

        if (zoom) {
            coordinates.zoom = parseFloat(zoom);
        }

        return coordinates;

    }
    return null;
}


export const DEFAULT_LATITUDE = 39.0181696;
export const DEFAULT_LONGITUDE = -100.6668835;
export const DEFAULT_ZOOM = 4;

const ZOOM_FOR_GEOLOCATION = 11;

export function getLocationArray() {
    return new Promise((resolve, reject) => {
        let coordinates = parseURLLatLong();

        if (coordinates) {
            console.log("found coordinates in url", coordinates)
            resolve(coordinates);
            return;
        }
        
        coordinates = new Coordinates();
        

        // Function to handle geolocation retrieval
        const getGeolocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        coordinates.lat = latitude;
                        coordinates.lon = longitude;
                        coordinates.zoom = ZOOM_FOR_GEOLOCATION;
                        resolve(coordinates);
                    },
                    () => {
                        // Use default coordinates when geolocation fails
                        coordinates.lat = DEFAULT_LATITUDE;
                        coordinates.lon = DEFAULT_LONGITUDE;
                        coordinates.zoom = DEFAULT_ZOOM;
                        resolve(coordinates);
                    }
                );
            } else {
                // Geolocation is not supported by this browser
                reject('Geolocation is not supported by this browser.');
            }
        };

        getGeolocation();

        // if (navigator.permissions) {
        //     // Use Permissions API to check geolocation permission has been PRIOR APPROVED
        //     navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        //         if (permissionStatus.state === 'granted') {
        //             coordinates.hasApprovedPermissions = true;
        //             getGeolocation();
        //         }
        //         // If permission has not been approved, prompt the user for permission, 
        //         // this will allow us to decide if we fly to the user's location or not
        //         else if (permissionStatus.state === 'prompt') {
        //             getGeolocation();
        //         } 
        //         else 
        //         {
        //             // Permission denied, use default coordinates
        //             coordinates.lat = DEFAULT_LATITUDE;
        //             coordinates.lon = DEFAULT_LONGITUDE;
        //             coordinates.zoom = DEFAULT_ZOOM;
        //             resolve(coordinates);
        //         }
        //     }).catch(() => {
        //         // Permissions API not supported or error occurred, attempt to get geolocation normally
        //         getGeolocation();
        //     });
        // } else {
        //     // Permissions API not supported, attempt to get geolocation normally
        //     getGeolocation();
        // }
    });
}
