import React, { useEffect } from "react";
import { RadioButton } from "./radio-button";

export function EnabledDashboardSections(props) {
    const [selectedSection, setSelectedSection] = React.useState(() => {
        return localStorage.getItem('dashboard-checked-section') || 'trail';
    });

    function onSectionClick(section) {
        setSelectedSection(section);
        localStorage.setItem('dashboard-checked-section', section);
        // props.setEnabledSection(section);
    }

    useEffect(() => {
        props.setEnabledSection(selectedSection);
    }, [selectedSection, props.setEnabledSection]);

    return (
        <ul className="flex w-full justify-center mt-2">
            <li>
                <RadioButton isChecked={selectedSection === 'trail'} clickHandler={() => onSectionClick('trail')} id="trail-section-toggle" name="trails" text="Trails" style="rounded-l-lg" />
            </li>
            <li>
                <RadioButton isChecked={selectedSection === 'climbing'} clickHandler={() => onSectionClick('climbing')} id="climbing-section-toggle" name="climbing" text="Climbing" style="" />
            </li>
            <li>
                <RadioButton isChecked={selectedSection === 'camping'} clickHandler={() => onSectionClick('camping')} id="camping-section-toggle" name="camping" text="Camping" style="rounded-r-lg" />
            </li>
        </ul>
    );
}
