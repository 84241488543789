import React from "react";
import classNames from "classnames";


export function ToggleButton(props) {
    const classes = classNames(
        `${props.isVisible ? `${props.checkedColor}` : "bg-gray-200"}`,
        `w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 
            rounded-full peer relative before:content-[''] before:absolute before:top-0.5 before:left-0.5 before:bg-white 
            before:border before:border-gray-300 before:rounded-full before:h-5 before:w-5 before:transition-all 
            peer-checked:before:translate-x-5`
        
    );

    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" onChange={props.handler} checked={props.isVisible}
                   className="sr-only peer"/>
            <span className="font-medium mr-2 ml-2">{props.name}</span>
            <div className={classes}></div>
        </label>
    );
}


