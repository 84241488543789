import React from "react";

export const MAP_TOKEN = 'pk.eyJ1IjoiZHllcndvbGYxIiwiYSI6ImNsNXJvYjhjNDAyMzYzZW1pMm5jMm96Z3QifQ.VlMDIZfaCYmc5TainhxNBQ'

export const MAP_STYLE_OUTDOORS = 'mapbox://styles/mapbox/'

export const TRAIL_COLOR = '#Fe6601'
export const CAMPSITE_COLOR = `#A855F7`
export const CLIMBING_COLOR = '#0047AB'

export const SELECTED_TRAIL_COLOR = '#FF0000'
export const SELECTED_CAMPSITE_COLOR = '#FF0000'
export const SELECTED_CLIMBING_COLOR = '#0047AB'

export const TRAIL_LAYER_ID = 'trails'
export const CAMPSITE_LAYER_ID = 'campsites'
export const CLIMBING_LAYER_ID = 'climbing'

export const CURRENT_TRAIL_LAYER_ID = 'current-trail'
export const CURRENT_CAMPSITE_LAYER_ID = 'current-campsite'
export const CURRENT_CLIMBING_LAYER_ID = 'current-climbing'

export const CURRENT_TRAIL_SOURCE_ID = 'current-trail'
export const CURRENT_CAMPSITE_SOURCE_ID = 'current-campsite'
export const CURRENT_CLIMBING_SOURCE_ID = 'current-climbing'

export const COMBINED_SOURCE_ID = 'combined'

export const LAYER_AUTO_SIZE_VALUES = [
    'interpolate',
    ['linear'],
    ['zoom'],
    0, 2.5,
    10, 4,
    12, 4.5,
    14, 4.7,
    18, 6,
    20, 10
]

// style to trails layer. used in a mapbox .addLayer()
export const TRAIL_LAYER = {
    'id': TRAIL_LAYER_ID,
    'type': 'line',
    'source': COMBINED_SOURCE_ID,
    // Source has several layers. We visualize the one with name 'sequence'.
    'source-layer': TRAIL_LAYER_ID,
    'layout': {
        'line-cap': 'round',
        'line-join': 'round'
    },
    'paint': {
        'line-color': TRAIL_COLOR,
        'line-width': LAYER_AUTO_SIZE_VALUES,
    },
    'maxZoom': 22
}

// campsite layer using vector tiles
export const CAMPSITE_LAYER = {
    'id': CAMPSITE_LAYER_ID, // Layer ID
    'type': 'circle',
    'source': COMBINED_SOURCE_ID, // ID of the tile source created above
    // Source has several layers. We visualize the one with name 'sequence'.
    'source-layer': CAMPSITE_LAYER_ID,
    'paint': {
        'circle-color': CAMPSITE_COLOR,
        'circle-radius': LAYER_AUTO_SIZE_VALUES,
    },
    'maxZoom': 22
}

// climbing layer using vector tiles
export const CLIMBING_LAYER = {
    'id': CLIMBING_LAYER_ID, // Layer ID
    'type': 'circle',
    'source': COMBINED_SOURCE_ID, // ID of the tile source created above
    // Source has several layers. We visualize the one with name 'sequence'.
    'source-layer': CLIMBING_LAYER_ID,
    'paint': {
        'circle-color': CLIMBING_COLOR,
        'circle-radius': LAYER_AUTO_SIZE_VALUES,
    },
    'maxZoom': 22
}

export const COMBINED_SOURCE = {
    'type': 'vector',
    'tiles': [ process.env.REACT_APP_TILE_SERVER_URL + '/tiles/{z}/{x}/{y}'],
}

// geojson source for current climbing route that only has one point
export const CURRENT_CLIMBING_SOURCE = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: []
    }
}

export const MAP_DRAW_STYLES = [
    // ACTIVE (being drawn)
    // line stroke
    {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#FF0000",
            "line-width": 4
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.1
        }
    },
    // polygon mid points
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        "id": "gl-draw-polygon-stroke-active",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
        }
    },
    // vertex point halos
    {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 7,
            "circle-color": "#FFFFFF"
        }
    },
    // vertex points
    {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 4,
            "circle-color": "#FFFC5C",
        }
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
        "id": "gl-draw-line-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#000",
            "line-width": 3
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill-static",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "paint": {
            "fill-color": "#000",
            "fill-outline-color": "#000",
            "fill-opacity": 0.1
        }
    },
    // polygon outline
    {
        "id": "gl-draw-polygon-stroke-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#000",
            "line-width": 3
        }
    },
    // Default style for points
    {
        "id": "gl-draw-point",
        "type": "circle",
        "paint": {
            "circle-radius": 6,
            "circle-color": "#FF0000"
        },
        "filter": ["all", ["==", "$type", "Point"], ["==", "mode", "selected"]]
    },
    // Style for points when being created
    {
        "id": "gl-draw-point-point",
        "type": "circle",
        "paint": {
            "circle-radius": 4,
            "circle-color": "#FF0000"
        },
        "filter": ["all", ["==", "$type", "Point"], ["==", "$mode", "point"]]
    },
    // Style for points' vertex
    {
        "id": "gl-draw-polygon-and-line-vertex-point",
        "type": "circle",
        "paint": {
            "circle-radius": 3,
            "circle-color": "#FFFFFF"
        },
        "filter": ["all", ["==", "$type", "Point"], ["in", "mode", "draw_polygon", "draw_line_string"], ["==", "$active", "true"]]
    }
]

export const Popup = ({slug, name, location}) => {

    function handleClick(e) {
        window.location = slug
    }

    return (
        <div onClick={handleClick}>
            <h1 className={"text-lg text-slate-600 pr-2 pl-2"}>{name}</h1>
        </div>
    )
}

export const CUSTOM_ATTRIBUTION = [
    '<a href="https://www.mapbox.com/about/maps/">© Mapbox</a>',
    '<a href="https://www.openstreetmap.org/about//">© OpenStreetMap/a>'
];
export const SATELLITE_MAP_NAME = "satellite-v9";
export const OUTDOORS_MAP_NAME = "outdoors-v12";