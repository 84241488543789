import {Disclosure, Menu, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import { Link } from 'react-router-dom';

export function LoggedInPanel() {
    return (
        <div className="flex">
            <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                <Menu as="div" className="ml-3 relative z-10">
                    <div>
                        <Menu.Button className="flex">
                            <span className="sr-only">Contribute</span>
                            <div className="text-white hover:text-slate-300 inline-flex items-center px-1 pt-1 text-md font-medium">Add Activity</div>
                            {/*<img*/}
                            {/*    className="h-8 w-8 rounded-full"*/}
                            {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                            {/*    alt=""*/}
                            {/*/>*/}
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({active}) => (
                                    <a href="/trails/add" className={classNames(active ? '' : '', 'block px-4 py-2 text-sm')}>
                                        New Trail
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({active}) => (
                                    <a href="/climbing/add" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm ')}>
                                        New Climbing Route
                                    </a>
                                )}
                            </Menu.Item>
                        </Menu.Items>

                    </Transition>
                </Menu>
            </div>
            <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                {/*>*/}
                {/*    <span className="sr-only">View notifications</span>*/}
                {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                {/*</button>*/}

                {/* Profile dropdown */}
                <Menu as="div" className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center z-10">
                    <div>
                        <Menu.Button className="flex">
                            <span className="sr-only">Open user menu</span>
                            <div className="text-white hover:text-slate-300 inline-flex items-center px-1 pt-1 text-md font-medium">My Account</div>
                            {/*<img*/}
                            {/*    className="h-8 w-8 rounded-full"*/}
                            {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                            {/*    alt=""*/}
                            {/*/>*/}
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({active}) => (
                                    <div
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm  cursor-pointer'
                                        )}
                                    >

                                        <Link to="/settings">Settings</Link>
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({active}) => (
                                    <div
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm cursor-pointer'
                                        )}
                                    >

                                        <Link to="/dashboard">Dashboard</Link>
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({active}) => (
                                    <div
                                        onClick={ handleLogout }
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm  cursor-pointer'
                                        )}
                                    >
                                        Sign out
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}

export function LoggedInHamburgerPanel() {
    return (
        <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-4 sm:px-6">
                {/*<div className="flex-shrink-0">*/}
                {/*    <img*/}
                {/*        className="h-10 w-10 rounded-full"*/}
                {/*        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                {/*        alt=""*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="ml-3">*/}
                {/*    <div className="text-base font-medium">Tom Cook</div>*/}
                {/*    <div className="text-sm font-medium ">tom@example.com</div>*/}
                {/*</div>*/}
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="ml-auto flex-shrink-0 bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                {/*>*/}
                {/*    <span className="sr-only">View notifications</span>*/}
                {/*    <BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                {/*</button>*/}
            </div>
            <div className="mt-3 space-y-1">
                {/*<Disclosure.Button*/}
                {/*    as="a"*/}
                {/*    href="#"*/}
                {/*    className="block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"*/}
                {/*>*/}
                {/*    Your Profile*/}
                {/*</Disclosure.Button>*/}
                <Disclosure.Button
                    as="div"
                    className="block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"
                >
                    <Link to="/settings">Settings</Link>
                </Disclosure.Button>
                <Disclosure.Button
                    as="div"
                    onClick={handleLogout}
                    className="block px-4 py-2 text-base font-medium text-white hover:bg-gray-100 sm:px-6"
                >
                    Sign out
                </Disclosure.Button>
            </div>
        </div>
    )
}

function handleLogout() {
    console.log("logged out")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("access_token")
    localStorage.setItem("is_logged_in", false)
    window.location.replace(window.location)
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}