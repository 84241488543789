import React from 'react';
import {
    CAMPSITE_COLOR,
    CAMPSITE_LAYER_ID,
    CLIMBING_COLOR,
    CLIMBING_LAYER_ID,
    TRAIL_COLOR,
    TRAIL_LAYER_ID,
    CURRENT_CAMPSITE_LAYER_ID,
    CURRENT_TRAIL_LAYER_ID,
    CURRENT_CLIMBING_LAYER_ID
} from "./map-constants";
import { SolidHeart} from "../solid-heart";
import { CommentDocumentSVG } from "../CommentDocumentSVG";

const iconSize = "h-10 w-10"

export const treeSVG = (iconSize, color="green") => (
<svg className={ iconSize +  " m-2"} width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 <path fill={color} d="m53.984 87.234v12.766h-9.1055v-12.602c-14.145 2.0312-30 7.3164-42.844-0.082032 4.2266-1.3828 8.293-3.2539 12.113-5.6094 3.9023-2.4375 7.2344-5.2031 10.082-8.1289-5.8555 0.16406-12.844-0.082031-17.641-3.8203 7.1562-1.625 17.48-8.2109 21.383-14.309-2.6836 1.0586-9.5938 3.332-11.707 0.40625 7.1562-3.332 15.527-9.5117 21.219-14.797-6.2617 1.625-8.6172 1.7891-14.797-0.48828 6.5039-2.6016 15.039-9.1875 18.535-15.285-2.7656 0.24219-6.2617 0.40625-8.9414-0.48828 4.7969-4.7969 17.398-25.039 17.562-24.555 0.32031-0.56641 12.84 19.676 17.637 24.473-2.6836 0.89453-6.1797 0.73047-8.9414 0.48828 3.5781 6.0977 12.031 12.684 18.535 15.285-6.1797 2.2773-8.5352 2.1133-14.797 0.48828 5.7734 5.2852 14.145 11.465 21.219 14.797-2.0312 2.9258-9.0234 0.65234-11.707-0.40625 3.9844 6.0977 14.309 12.684 21.383 14.309-4.7969 3.6602-11.789 3.9844-17.641 3.8203 2.7656 2.9258 6.1797 5.6914 10.082 8.1289 3.8203 2.3594 7.8867 4.2266 12.113 5.6094-13.094 7.6445-29.352 1.9531-43.742 0z" fill-rule="evenodd"/>
</svg>
)

export const citySVG = (iconSize, color=CLIMBING_COLOR) => (
<svg className={ iconSize +  " m-2"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="size-6">
  <path strokeLinejoincap="round" strokeLinejoinjoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" fill={color} />
</svg>
)

export const climbingSVG = (iconSize, color=CLIMBING_COLOR) => (
    <svg className={ iconSize +  " m-2"} width="1200pt" height="1200pt" version="1.1"
         viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path d="m195.6 464.4 28.801-13.199c-12-28.801-20.398-52.801-22.801-68.398-16.801-106.8 48-243.6 228-294 177.6-49.199 403.2-10.801 445.2 124.8 3.6016 12 8.3984 30 13.199 54l-134.4 24c-3.6016-16.801-7.1992-31.199-9.6016-39.602-8.3984-30-138-72-277.2-33.602-115.2 33.602-135.6 109.2-130.8 144 1.1992 7.1992 6 19.199 12 34.801l28.801-13.199c39.602 96 176.4 352.8 238.8 447.6 16.801 25.199 34.801 49.199 52.801 68.398l-26.398 16.801c43.199 49.199 104.4 98.398 157.2 72 64.801-30 69.602-94.801 69.602-110.4 0-2.3984-1.1992-6-1.1992-8.3984l134.4-24c1.1992 6 2.3984 10.801 2.3984 13.199l1.1992 7.1992c4.8008 63.602-21.602 188.4-147.6 246-2.3984 1.1992-3.6016 1.1992-6 2.3984-105.6 45.602-220.8 1.1992-324-123.6l-26.398 16.801c-18-21.602-34.801-44.398-50.398-68.398-42.004-60-194.4-326.4-255.6-475.2zm660 342 134.4-24c-20.398-112.8-60-318-87.602-452.4l-134.4 24c25.199 123.6 62.398 321.6 87.602 452.4z"
              fill={color}/>
    </svg>
)

export const trailSVG = (iconSize, color=TRAIL_COLOR) => (
    <svg className={ iconSize + " m-2" }  width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="m7.9805 65.672h15.637v-0.58594c0-7.5508-6.1406-13.695-13.691-13.695h-1.9453z"/>
        <path fill={color} d="m7.9805 25.098v23.168h1.9453c9.2734 0 16.816 7.5469 16.816 16.82v0.58594h14.121c2.207 0 4.2852 0.85938 5.8438 2.4219l3.3633 3.3633c0.25781 0.25781 0.60547 0.40234 0.97266 0.40234h40.059v-3.6484c0-14.395-10.656-16.508-20.961-18.551l-0.8125-0.16406c-5.6719-1.1172-10.555-4.543-13.613-9.3594l-10.324 2.9258c-0.14062 0.039062-0.28516 0.0625-0.42578 0.0625-0.67969 0-1.3047-0.44922-1.5-1.1367-0.23438-0.82812 0.24609-1.6953 1.0781-1.9297l9.6484-2.7344c-0.019531-0.042969-0.042968-0.078125-0.0625-0.12109l-1.9961-4.6211-10.105 2.8672c-0.14062 0.039062-0.28516 0.0625-0.42578 0.0625-0.67969 0-1.3047-0.44922-1.5-1.1367-0.23437-0.82812 0.24609-1.6953 1.0781-1.9297l9.707-2.7539-2.0508-4.75-10.938 3.1055c-0.14453 0.039063-0.28906 0.0625-0.42969 0.0625-0.67969 0-1.3047-0.44922-1.5-1.1367-0.23438-0.82812 0.24609-1.6953 1.0742-1.9297l10.543-2.9922-3.3594-7.7734h-5.0859c-2.1719 0-4.2656 0.91016-5.75 2.4961l-4.207 4.4961c-1.2969 1.3867-3.1289 2.1797-5.0273 2.1797l-14.516-0.011719c-0.91406 0-1.6602 0.74219-1.6602 1.6602z"/>
        <path fill={color} d="m47.867 73.664-3.3633-3.3633c-0.97266-0.97266-2.2617-1.5078-3.6367-1.5078l-33.996 0.003906c-0.34375 0-0.62109 0.27734-0.62109 0.62109v15.691c0 0.34375 0.28125 0.62109 0.62109 0.62109h3.793v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625 0.86328 0 1.5625 0.69922 1.5625 1.5625v3.8281h7.5859v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625s1.5625 0.69922 1.5625 1.5625v3.8281h3.5977l1.3906-2.9648c0.73047-1.5664 2.125-2.6523 3.8281-2.9727 1.7031-0.32422 3.4023 0.17969 4.6641 1.375l4.8047 4.5664h8.4062v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625 0.86328 0 1.5625 0.69922 1.5625 1.5625v3.8281h7.5859v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625 0.86328 0 1.5625 0.69922 1.5625 1.5625v3.8281h7.5859v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625s1.5625 0.69922 1.5625 1.5625v3.8281h7.5859v-3.8281c0-0.86328 0.69922-1.5625 1.5625-1.5625s1.5625 0.69922 1.5625 1.5625v3.8281h7.3047v-10.746h-42.703c-1.2031 0-2.332-0.46875-3.1797-1.3164z"/>
    </svg>
)

export const campsiteSVG = (iconSize, color=CAMPSITE_COLOR) => (
    <svg className={ iconSize + " m-2" } width="1200pt" height="1200pt" version="1.1"
         viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m1100 934.79-19.102-17.199-21.887 40.199-442.99-699v-44.375h-28.125v44.375l-445.1 702.32-23.699-43.512-19.102 17.188 27.5 50.477-0.21094 0.3125h949.31l-2.3125-3.6367zm-571.27 34.098 73.211-290.46 73.211 290.46z"
            fill={color}/>
    </svg>
)

export function Popup({popupVisible, activityType, activityName, specialMarkerCategory=null}) {


    return (
        <>
        { popupVisible ? (

                <div style={{ pointerEvents: "none" }} className=" absolute z-[99999] bg-white top-4 left-1/2 -translate-x-1/2 flex items-center rounded-xl border border-gray-100 p-4 shadow-xl">
                    <div className="flex mx-auto items-start gap-4">

                        <div className={"rounded-xl mt-1 bg-slate-600 "}>

                            { activityType === CAMPSITE_LAYER_ID || activityType === CURRENT_CAMPSITE_LAYER_ID ? (
                                campsiteSVG(iconSize)
                                ) : activityType === TRAIL_LAYER_ID || activityType === CURRENT_TRAIL_LAYER_ID ? (
                                trailSVG(iconSize)
                                ) : activityType === CLIMBING_LAYER_ID || activityType === CURRENT_CLIMBING_LAYER_ID ? (
                                climbingSVG(iconSize, "#fff")  ) : 
                                  activityType === "user-favorites" ? ( <SolidHeart classes={iconSize + " m-2"} /> ) : 
                                  activityType === "user-reviews" ? ( <CommentDocumentSVG classes={iconSize + " m-2"} />) : null }
                        </div>


                        <div className="flex-1">
                            <strong className="block font-medium mt-2"> {activityName} </strong>
                            <p className="mt-1 text-sm text-gray-700">
                                Click for Description and Print Options
                            </p>
                        </div>
                    </div>
                </div>
        ) : null }
        </>

    )
}


