import React from 'react';
import {useLogin} from "../hooks/use-login";
import {Navigate} from "react-router-dom";

import {CURRENT_PAGE_URL} from "../pages/auth/redirect-to-last-page";


const ProtectedRoute = ({redirectPath='/', children}) => {

    const user = useLogin();

    if (!user.isLoggedIn) {
        sessionStorage.setItem(CURRENT_PAGE_URL, window.location.pathname)
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute;