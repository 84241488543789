import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useGoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {

        if (process.env.NODE_ENV !== 'production') return;

        // Access the global gtag function from the window object
        const trackPageView = (url) => {
            window.gtag('config', 'G-K4JSLXQ59E', {
                page_path: url,
            });
        };

        // Call trackPageView with the current URL when the component mounts
        trackPageView(location.pathname + location.search);
    }, [location]); // Add location as a dependency to re-run the effect when it changes
}

function GoogleAnalytics() {
    useGoogleAnalytics();
    return null;
}

export default GoogleAnalytics

