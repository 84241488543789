import {useState} from "react";
import http, {isAccessTokenExpired, isRefreshTokenExpired} from "../components/http-common";


export const useLogin = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(!isAccessTokenExpired() && !isRefreshTokenExpired())
    const [error, setError] = useState('')
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsername = event => {
        setUsername(event.target.value)
    }

    const handlePassword = event => {
        setPassword(event.target.value)
    }

    const fetchLogin = async () => {
        const res = await http.post('/token/', {email: username, password: password});

        if (res.status === 200) {
            let data = res.data

            localStorage.setItem('refresh_token', data.refresh)
            localStorage.setItem('access_token', data.access)
            localStorage.setItem('is_logged_in', true)
            console.log(data.access)
            console.log(data.refresh)
            setError("")
            return true
        } else {
            setError(res.response.data.detail)
            return false
        }
    }

    return {
        error,
        setError,
        username,
        setUsername,
        password,
        setPassword,
        fetchLogin,
        handleUsername,
        handlePassword,
        isLoggedIn,
        setIsLoggedIn
    }


}