import React, {Fragment, useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon} from "@heroicons/react/solid";

export default function FilterModal(props) {
    const cancelButtonRef = useRef(null)
    const toggleCategory = (category) => {
        console.log("toggle category", category)

        let newCategories = [];

        if (props.selectedCategories.includes(category)) {

            newCategories = props.selectedCategories.filter((c) => c !== category);
            props.setSelectedCategories(newCategories);
        } else {

            newCategories = [...props.selectedCategories, category];
            props.setSelectedCategories(newCategories);
        }
        // set localstorage
        localStorage.setItem("selectedCategories", JSON.stringify(newCategories));

    };

    return (
        <Transition.Root show={props.isVisible} as={Fragment}>
            <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={() => {props.setIsVisible(false)}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-20 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center sm:items-center sm:p-0">
                        <input readOnly={true} checked={true} type="checkbox" id="my-modal" className="modal-toggle" />
                        <div className="modal">

                            <div className="relative modal-box max-w-sm mt-4">
                                <XIcon onClick={() => { props.setIsVisible(false)}}
                                       className={"h-8 absolute right-4 top-2 hover:rounded-full hover:bg-slate-300 hover:cursor-pointer"} ></XIcon>
                                <div className={"w-full text-2xl"}>{"Included Search Categories:"}</div>
                                <div className={"w-1/2"}>
                                    <div className="mt-4">
                                        <label className="flex block">
                                            <input type="checkbox" value={3}
                                                   onChange={() => toggleCategory(3)}
                                                   checked={props.selectedCategories.includes(3)} />
                                            <div className={"pl-2"}>Campgrounds</div>
                                        </label>
                                        <label className="flex block mt-2">
                                            <input className={""} type="checkbox" value={6}
                                                   onChange={() => toggleCategory(6)}
                                                   checked={props.selectedCategories.includes(6)}/>
                                            <div className={"pl-2"}>Cities</div>
                                        </label>
                                        <label className="flex block mt-2">
                                            <input type="checkbox" value={4}
                                                   onChange={() => toggleCategory(4)}
                                                   checked={props.selectedCategories.includes(4)}/>
                                            <div className={"pl-2"}>Climbing Routes</div>
                                        </label>
                                        <label className="flex block mt-2">
                                            <input type="checkbox" value={1}
                                                   onChange={() => toggleCategory(1)}
                                                   checked={props.selectedCategories.includes(1)}/>
                                            <div className={"pl-2"}>Trails</div>
                                        </label>
                                        <label className="flex block mt-2">
                                            <input type="checkbox" value={5}
                                                   onChange={() => toggleCategory(5)}
                                                   checked={props.selectedCategories.includes(5)}/>
                                            <div className={"pl-2"}>Zip Code</div>
                                        </label>
                                        <label className="flex block mt-2">
                                            <input type="checkbox" value={7}
                                                   onChange={() => toggleCategory(7)}
                                                   checked={props.selectedCategories.includes(7)}/>
                                            <div className={"pl-2"}>Park</div>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
