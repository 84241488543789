import React, {useState} from "react";
import http from "../../components/http-common";
import CloseToLastPage from "../../components/close-to-last-page";
import { useTheme } from "../../contexts/theme-context";

export const CURRENT_PAGE_URL = 'current_page_url'

export function ChangePasswordPage(props) {
    const {currentTheme} = useTheme()

    const [showPassword, setShowPassword] = useState(false)

    const [emailError, setError] = useState('')
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    // listen for the Enter key to be pressed and submit the form
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleCurrentPassword = event => {
        setCurrentPassword(event.target.value)
    }

    const handleNewPassword = event => {
        setNewPassword(event.target.value)
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const fetchLogin = async (e) => {
        e.preventDefault()
        const res = await http.post('/auth/users/set_password/', {current_password: currentPassword, new_password: newPassword});
        console.log(res)

        if (res.status === 204) {
            setError("")
            return true
        } else {
            setError(res.response.data.detail)
            return false
        }
    };

    const handleSubmit = async (e) => {
        fetchLogin(e)
            .then((value) => {
                if (value === true) {

                    // get the currentURL location from session storage if it exists and redirect to it
                    let currentURL = sessionStorage.getItem(CURRENT_PAGE_URL)
                    if (currentURL) {
                        window.location.assign(currentURL)
                    }
                    else {
                        window.location.assign('/')
                    }
                }
            })
    }

    const ShowPasswordIcon = () => {

        if (showPassword === true) {
            return (
                <svg onClick={handleShowPassword} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLineJoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                </svg>
            )

        }
        else {
            return(
                <svg onClick={handleShowPassword} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                </svg>
            )
        }
    }

    return (
        <div className="bg-cover bg-center bg-hero-pattern flex min-h-full flex-col justify-center w-full h-screen">
            <div className={"flex flex-col justify-center items-center  backdrop-blur-lg h-screen w-full"}>


                <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-xl">
                    <div className="relative filter-none bg-white pb-8 px-4 shadow sm:rounded-md md:px-10">
                        <CloseToLastPage/>
                        <h2 className="pt-4 text-center text-xl tracking-wider text-gray-800">Change Your Password</h2>

                        { emailError !== "" ?
                            <div className={"mt-4 text-red-500 text-sm text-center"}>
                                <ul>
                                    <li>{emailError}</li>
                                </ul>
                            </div>
                            : <></>
                        }


                        <div className="mx-auto mt-8 md:max-w-sm space-y-4">
                            <div>

                                <label htmlFor="currentPassword" className="block text-md font-medium text-gray-700">
                                    Current Password
                                </label>
                                <div className="mt-1">

                                    <input
                                        id="currentPassword"
                                        name="currentPassword"
                                        type="password"
                                        onChange={handleCurrentPassword}
                                        value={currentPassword}
                                        autoComplete="email"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="newPassword" className="block text-md font-medium text-gray-700">
                                    New Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        id="newPassword"
                                        name="newPassword"
                                        type={showPassword ? "text" : "password"}
                                        onChange={handleNewPassword}
                                        value={newPassword}
                                        autoComplete="new-password"
                                        required
                                        className="block inline w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm pr-10"
                                    />
                                    <div className={"absolute inset-y-0 right-3 flex items-center leading-5"}>
                                        <ShowPasswordIcon />
                                    </div>
                                </div>
                            </div>
                            <div className={ "flex flex-col items-center" }>
                                <button
                                    onKeyDown={handleKeyPress}
                                    onClick={handleSubmit}
                                    className={`${currentTheme.bg.primary} flex w-full w-1/3 items-center justify-center rounded-md border border-transparent py-3 px-4 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                >
                                    Submit
                                </button>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
}