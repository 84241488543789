import React, {useContext} from "react";
import {MapContext} from "../contexts/map-context";
import {CAMPSITE_COLOR, TRAIL_COLOR, CLIMBING_COLOR} from "./maps/map-constants";
import {ToggleButton} from "./toggle-button";
import classNames from "classnames";

const trailBg = `bg-[#Fe6601]`
const campBg = `bg-[#A855F7]`
const climbBg = `bg-[#0047AB]`

export function EnabledActivities({style = ''}) {

    const mapSettings = useContext(MapContext)



    function campingToggleHandler() {
        mapSettings.setCampingToggLayerVisible(!mapSettings.campingToggleLayerVisible)
    }

    function trailsToggleHandler() {
        mapSettings.setTrailToggLayerVisible(!mapSettings.trailToggleLayerVisible)
    }

    function climbingroutesToggleHandler() {
        mapSettings.setClimbingToggLayerVisible(!mapSettings.climbingToggleLayerVisible)
    }

    const enabledActivityStyle = classNames(
        "flex whitespace-pre sm:whitespace-normal justify-center mt-4 text-center text-black",
        style
    )

    // note there is some custom css in index.css that is not included here
    return <div id={"enabled-activities"} className={enabledActivityStyle}>
        <ToggleButton name={"Trails        "} handler={trailsToggleHandler} isVisible={mapSettings.trailToggleLayerVisible} checkedColor={trailBg} />
        <ToggleButton name={"Climbing  "} handler={climbingroutesToggleHandler} isVisible={mapSettings.climbingToggleLayerVisible} checkedColor={climbBg} />
        <ToggleButton name={"Camping  "} handler={campingToggleHandler} isVisible={mapSettings.campingToggleLayerVisible} checkedColor={campBg} />
    </div>
}