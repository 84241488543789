import React, { useState, useEffect } from 'react';
import http from './http-common';
import {DashboardCounter} from './dashboard-counter';
import { CommentDocumentSVG } from './CommentDocumentSVG';


export function CommentCounter(activityType) {
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchComments = async () => {
            let totalCount = 0;  // To accumulate results from each API call

            // Function to fetch and update the comment count
            const updateCommentCount = async (activityType) => {
                try {
                    const response = await http.get(`/${activityType}/reviews/?me=True`);
                    totalCount += response.data.count;  // Aggregate counts from each call
                } catch (error) {
                    console.error('Error fetching comment count:', error);
                }
            };

            // Execute the fetch function three times
            await updateCommentCount("trails");  // First call
            await updateCommentCount("camping");  // Second call
            await updateCommentCount("climbing");  // Third call

            // After all calls, update the component state
            setCount(totalCount);
            setIsLoading(false);
        };

        fetchComments();  // Invoke the API call function
    }, [activityType]);  // Effect depends on the activityType to re-run if it changes

    return (
        <>
            <DashboardCounter isLoading={isLoading} icon={<CommentDocumentSVG classes='h-8'/>} count={count.toString()} name="Tips" />
        </>
    );
}
