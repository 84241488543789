export const CURRENT_PAGE_URL = 'current_page_url'

export function redirectToLastPage() {

    // get the currentURL location from session storage if it exists and redirect to it
    let currentURL = sessionStorage.getItem(CURRENT_PAGE_URL)
    if (currentURL) {
        window.location.assign(currentURL)
    } else {
        window.location.assign('/')
    }
}