import React, {useState} from "react";
import DashboardFavorites from "../components/dashboard-favorites";
import DashboardComments from "../components/dashboard-comments";
import { EnabledDashboardSections } from "../components/enabled-dashboard-sections";
import DashboardPhotos from "../components/dashboard-photos";

export function DashboardDetailSections(props) {

    const [enabledSection, setEnabledSection] = useState(null)

    return (

        <>
        <div className="w-full mt-8">        
            <div className="m-auto justify-center items-center w-fit">
                <EnabledDashboardSections setEnabledSection={setEnabledSection} />
            </div>
        </div>
        <div className={"w-full gap-4 mt-4"}>
            <div className="mt-4">

                { enabledSection === 'camping' && (
                
                    <div id="camping-section" className="mt-4">
                        <div className="text-xl text-strong">Camping</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <DashboardFavorites title={"Favorites"} activity_type={"campsites"}></DashboardFavorites>
                            <DashboardComments title={"Comments"} activity_type={"campsites"}></DashboardComments>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <DashboardPhotos title={"Photos"} activity_type={"campsites"}></DashboardPhotos>
                        </div>
                    </div>

                )}

                { enabledSection === 'trail' && (
                    
                <div id="trail-section">
                    <div className="text-xl text-strong">Trails</div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                        <DashboardFavorites title={"Favorites"} activity_type={"trails"}></DashboardFavorites>
                        <DashboardComments title={"Comments"} activity_type={"trails"}></DashboardComments>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <DashboardPhotos title={"Photos"} activity_type={"trail"}></DashboardPhotos>
                        </div>
                </div>

                )}

                { enabledSection === 'climbing' && (
                
                <div id="climbing-section" className="mt-4">
                    <div className="text-xl text-strong">Climbing</div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                        <DashboardFavorites title={"Favorites"} activity_type={"climbing"}></DashboardFavorites>
                        <DashboardComments title={"Comments"} activity_type={"climbing"}></DashboardComments>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <DashboardPhotos title={"Photos"} activity_type={"climbing"}></DashboardPhotos>
                        </div>
                </div>

                )}


            </div>
        </div>
    </>
    )
}