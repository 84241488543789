import React from "react";
import {useTheme} from "../contexts/theme-context";
import classNames from "classnames";

export function RadioButton({clickHandler= () => {}, isChecked, id, name, text, style}) {
    const {currentTheme} = useTheme()
    const classes = classNames(
        `${isChecked ? currentTheme.bg.primary : ''}`, 
        currentTheme.text.primary, 
        currentTheme.border, 
        style,
        `inline-flex sm:pl-15 sm:pr-15 pl-10 pr-10 items-center border-2 peer-checked:text-white justify-between w-full p-1 cursor-pointer`
        )

    return (
        <>
            <input checked={isChecked} onChange={clickHandler} type="radio" id={id} name={name} value={id} className="hidden peer"/>
            <label htmlFor={id}
                   className={classes} >
                <div className="block">
                    <div className="w-full text-lg font">{text}</div>
                </div>
            </label>
        </>
    )
}