import React, {useContext, useEffect} from "react";
import {MapContext} from "../contexts/map-context";
import {RadioButton} from "./radio-button";
import {OUTDOORS_MAP_NAME, SATELLITE_MAP_NAME} from "./maps/map-constants";

export function EnabledStyles(props) {

    const mapSettings = useContext(MapContext)

    function outdoorsStyleHandler() {
        mapSettings.setMapBaseStyle(OUTDOORS_MAP_NAME)
    }

    function satelliteStyleHandler() {
        mapSettings.setMapBaseStyle(SATELLITE_MAP_NAME)
    }

    const [outdoorsChecked, setOutdoorsChecked] = React.useState(mapSettings.mapBaseStyle === OUTDOORS_MAP_NAME)
    const [satelliteChecked, setSatelliteChecked] = React.useState(mapSettings.mapBaseStyle === SATELLITE_MAP_NAME)

    useEffect(() => {
        setOutdoorsChecked(mapSettings.mapBaseStyle === OUTDOORS_MAP_NAME)
        setSatelliteChecked(mapSettings.mapBaseStyle === SATELLITE_MAP_NAME)


    }, [mapSettings.mapBaseStyle])

    return (
        <ul className="flex w-full justify-center mt-2">
            <li>
                <RadioButton isChecked={outdoorsChecked} clickHandler={outdoorsStyleHandler} id="outdoors-toggle" name="outdoors" text="Topo" style="rounded-l-lg" />
            </li>
            <li>
                <RadioButton isChecked={satelliteChecked} clickHandler={satelliteStyleHandler} id="satellite-toggle" name="satellite" text="Satellite" style="rounded-r-lg" />
            </li>
        </ul>
    )
}

