import {useState} from "react";

export const useMapSettings = () => {
    const [campingToggleLayerVisible, setCampingToggLayerVisible] = useState(true)
    const [trailToggleLayerVisible, setTrailToggLayerVisible] = useState(true)
    const [climbingToggleLayerVisible, setClimbingToggLayerVisible] = useState(true)
    const [latitude, setLatitude] = useState(32.7157)
    const [longitude, setLongitude] = useState(-117.1611)
    const [mapBaseStyle, setMapBaseStyle] = useState("outdoors-v12")


    function isVisibility(layerVisible) {
        if (layerVisible === true) {
            return "visible"
        }
        else {
            return "none"
        }
    }

    return {
        longitude,
        setLongitude,
        latitude,
        setLatitude,
        campingToggleLayerVisible,
        setCampingToggLayerVisible,
        trailToggleLayerVisible,
        setTrailToggLayerVisible,
        climbingToggleLayerVisible,
        setClimbingToggLayerVisible,
        isVisibility,
        mapBaseStyle,
        setMapBaseStyle
    }
}