import React, {Fragment, useEffect, useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TypeAheadSearch from "./type-ahead-search";


export default function CommandKSearch(props) {
    const typeAheadRef = useRef(null);

    useEffect(() => {
        // Handler to close overlay when click is outside of the input
        const handleClickOutside = (event) => {
            console.log("clicked outside 1")
            if (typeAheadRef.current && !typeAheadRef.current.contains(event.target)) {
                console.log("clicked outside 2")
                props.setIsVisible(false);
            }
        };

        // Add the click event listener to the document
        document.addEventListener("click", handleClickOutside);

        return () => {
            // Clean up the listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <Transition.Root show={props.isVisible} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={() => {props.setIsVisible(false)}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" >

                <Dialog.Panel className="absolute top-1/3 transform overflow-hidden transition-all">

                        <div ref={typeAheadRef} className="flex min-h-full items-end justify-center sm:items-center sm:p-0">
                            <TypeAheadSearch></TypeAheadSearch>
                        </div>

                </Dialog.Panel>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}