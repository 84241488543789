import React from 'react';
import { Link } from 'react-router-dom';
import {useLocation} from "react-router-dom";
import {useTheme} from "../contexts/theme-context";

const Footer = (props) => {
    const {currentTheme} = useTheme()

    const background =  props.backgroundColor || "bg-[#071b1f]"
    const style = "text-white hover:text-slate-300 items-center inline-flex pt-1 font-medium"
    const isHomePage = useLocation().pathname === "/"

    return (
        <div className={`flex text-white flex-col items-center justify-center space-y-8 ${background}`}>
            <div className="flex justify-center mt-8 mb-8 items-center space-x-4 sm:space-x-28">
                <Link to="/privacy-policy" className={'hover:text-slate-300'}>Privacy Policy</Link>
                <Link to="/terms-and-conditions" className={'hover:text-slate-300'}>Terms of Service</Link>
                <Link to="/contact" className={'hover:text-slate-300'}>About Us</Link>
            </div>

            { !isHomePage &&
                <div className={"brusher select-none sm:text-6xl  text-6xl text-center pt-4 pb-10"}>Adventuresum</div>
            }
            {/*<div className="text-center text-white text-sm px-4 mt-4 pb-4">*/}
            {/*    As an Amazon Associate, we may earn commissions from qualifying purchases from Amazon.com.*/}
            {/*</div>*/}
        </div>
    )
}

export default Footer