import React, {useContext, useEffect} from "react";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {useTheme} from "../contexts/theme-context";

const Skeleton = () => {
    const {currentTheme} = useTheme()
    return (
        <div className={`${currentTheme.bg.skeleton} mx-auto w-8`}> </div>
    )
}

export function DashboardCounter({name, count, icon, isLoading=true}) {

    useEffect(() => {


    }, [])

    return (
        
        <div className={`flex text-center w-full justify-center mt-2 `}>
            {/*<Tippy placement="bottom" content={`${name}`}>*/}
                <div className="w-24">
                    {isLoading ? <Skeleton></Skeleton> : <div className="font-bold">{count}</div>}
                    {/* <div>{count}</div> */}
            
                    <div className="flex justify-center">
                        <div className="">{icon}</div>
                    </div>
                    <div>{name}</div>
                </div>
            {/*</Tippy>*/}
        </div>
        
    )
}