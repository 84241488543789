import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import http from '../http-common';
import {redirectToLastPage} from "../../pages/auth/redirect-to-last-page";

function GoogleLoginComponent() {
    const handleLoginSuccess = (response) => {
        // You can send the response.tokenId to your backend for verification and further processing
        console.log(response);

        // send the token to the backend
        http.post('/o/google-login/', {token: response.credential})
            .then(response => {
                console.log(response)
                localStorage.setItem('refresh_token', response.data.refresh)
                localStorage.setItem('access_token', response.data.access)
                localStorage.setItem('is_logged_in', true)
                redirectToLastPage()

            })
            .catch(error => {
                console.log(error)
            })
    };

    return (
        <GoogleOAuthProvider clientId="991024774003-k4karphp2rqlu1g7eh6s7j7qjajp7ku0.apps.googleusercontent.com">
            <GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.log("Login Failed")} />
        </GoogleOAuthProvider>
    )
}

export default GoogleLoginComponent;