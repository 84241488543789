import React, { useState, useEffect } from 'react';
import {DashboardCounter} from './dashboard-counter';
import {Camera} from './camera';
import http from './http-common';


export function PhotoCounter(activityType) {
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFavorites = async () => {
            let totalCount = 0;  // To accumulate results from each API call

            // Function to fetch and update the favorite count
            const updateFavoriteCount = async (activityType) => {
                try {
                    const response = await http.get(`/photos/?current_user_only=true`);

                    if (response.data.count) {
                        totalCount = response.data.count;
                    }

                } catch (error) {
                    console.error('Error fetching favorite count:', error);
                }
            };

            // Execute the fetch function three times
            await updateFavoriteCount("trails");  // First call

            // After all calls, update the component state
            setCount(totalCount);
            setIsLoading(false);
        };

        fetchFavorites();  // Invoke the API call function
    }, [activityType]);  // Effect depends on the activityType to re-run if it changes

    return (
        <>
            <DashboardCounter isLoading={isLoading} icon={<Camera classes={"h-8"}/>} count={count.toString()} name="Photos" />
        </>
    );
}