import Nav from "../components/nav";
import React from 'react';
import Footer from "../components/footer";

const PrivacyPolicyPage = (props) => {

    return (
        <div className="">
            <Nav/>
            <div className="">
                <div>
                    <div className={"container mx-auto mt-10 px-4 text-2xl font-bold"}>Privacy Policy for AdventureSum</div>

                    <div class="container mx-auto px-4">
                        <p class="text-lg font-semibold">Last Updated: January 6, 2024</p>

                        <h1 class="text-2xl font-bold mt-5 mb-2">Welcome to AdventureSum, an outdoor and recreation location finder.</h1>
                        <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information.</p>

                        <h2 class="text-xl font-semibold mt-4">1. Information We Collect</h2>
                        <ul class="list-disc ml-5">
                            <li><strong>Visiting the Site:</strong> We do not collect any personal data from visitors who simply browse our website.</li>
                            <li><strong>Account Creation:</strong> When you create an account, we collect your email address for login and account management purposes.</li>
                            <li><strong>User-Submitted Location Data:</strong> If you choose to share location information (such as geo-data for outdoor locations) with other users, this information will be stored and displayed on our site as part of your contribution.</li>
                        </ul>

                        <h2 class="text-xl font-semibold mt-4">2. Use of Your Information</h2>
                        <ul class="list-disc ml-5">
                            <li><strong>Account Management:</strong> Your email address is used solely for the purpose of managing your account, including login authentication and password recovery.</li>
                            <li><strong>Location Data:</strong> Any geo-location data you submit is used to enhance the content and value of our site, providing insights and information about outdoor locations to our users.</li>
                        </ul>

                        <h2 class="text-xl font-semibold mt-4">3. Data Retention Upon Account Deletion</h2>
                        <ul class="list-disc ml-5">
                            <li><strong>Account Deletion:</strong> If you delete your account, your personal information, such as your email address, will be removed from our systems. However, any geo-data or location information that you have shared on the site will remain. This is to ensure the continuity and integrity of the content available to other users. We will retain this data anonymously, without any personal identifiers.</li>
                        </ul>

                        <h2 class="text-xl font-semibold mt-4">4. Sharing of Your Information</h2>
                        <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>

                        <h2 class="text-xl font-semibold mt-4">5. Data Protection</h2>
                        <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

                        <h2 class="text-xl font-semibold mt-4">6. Consent</h2>
                        <p>By using our site, you consent to our website's privacy policy.</p>

                        <h2 class="text-xl font-semibold mt-4">7. Changes to our Privacy Policy</h2>
                        <p>If we decide to change our privacy policy, we will update the Privacy Policy modification date above.</p>

                        <h2 class="text-xl font-semibold mt-4">8. Contact Us</h2>
                        <p>If there are any questions regarding this privacy policy, you may contact us using the information below:</p>
                        <p class="italic pb-10">support@adventuresum.com</p>
                    </div>


                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default PrivacyPolicyPage;