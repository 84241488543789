import axios from 'axios'
import jwt_decode from "jwt-decode";

// Function to decode JWT and get expiration time
const getTokenExpiration = (token) => {
    const { exp } = jwt_decode(token);
    return exp;
};

const http = axios.create({
    baseURL: process.env.REACT_APP_WEBSVC_URL
});
http.defaults.headers['Content-Type'] = 'application/json'
http.defaults.headers['Accept'] = 'application/json'

console.log(http)

const refreshAccessToken = async () => {

    try {
        const res = await http.post('/token/refresh/', {refresh: localStorage.getItem('refresh_token')});
        console.log("res")
        console.log(res)
        console.log("data")
        console.log(res.data)
        let data = res.data
        localStorage.setItem("access_token", data.access)
        console.log("updated token")
        return data.access
    } catch (error) {
        console.log(error)
        return null
    }
}

const getAccessToken = () => {
    return localStorage.getItem('access_token');
}

export const isRefreshTokenExpired = () => {
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
        return true;
    }

    const decodedToken = jwt_decode(refreshToken);
    const expirationTime = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

    return expirationTime < currentTime;
};

export const isAccessTokenExpired = () => {
    const accessToken = getAccessToken(); // Retrieve the access token from your state management or storage solution

    if (!accessToken) {
        return true;
    }

    const tokenExpiration = getTokenExpiration(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiration = tokenExpiration - currentTime;
    const refreshThreshold = 60; // Refresh the token if it's going to expire in less than 60 seconds

    return timeUntilExpiration <= refreshThreshold
}

export const refreshTokenTimer = () => {
    const accessToken = getAccessToken(); // Retrieve the access token from your state management or storage solution

    if (!accessToken) {
        return;
    }

    const tokenExpiration = getTokenExpiration(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiration = tokenExpiration - currentTime;
    const refreshThreshold = 60; // Refresh the token if it's going to expire in less than 60 seconds
    if (timeUntilExpiration <= refreshThreshold) {
        refreshAccessToken();
    } else {
        const refreshTime = timeUntilExpiration - refreshThreshold;
        setTimeout(refreshAccessToken, refreshTime * 1000, accessToken);
    }
};

export const clearTokenExpirationTimer = () => {
    clearTimeout(refreshAccessToken);
}

// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
http.interceptors.request.use(
    async config => {
        const accessToken = getAccessToken(); // Retrieve the access token from your state management or storage solution
        if (accessToken) {
            config.headers = {
                'Authorization': "Bearer " + localStorage.getItem('access_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        return config;
        },
    error => {
      Promise.reject(error)
});

// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
http.interceptors.response.use(
    (response) => {
        return response
  },
    async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
        console.log("axios intercepter trying to refresh 403")
        originalRequest._retry = true;
    }
    if (error.response.data.detail === "No active account found with the given credentials") {
        return error;

    }
    if (error.response.status === 401 && !originalRequest._retry) {
        // console.log("axios intercepter trying to refresh 401")
        originalRequest._retry = true;
        if (error.response.data.detail === "Given token not valid for any token type") {
            const access_token = await refreshAccessToken();
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            localStorage.setItem("is_logged_in", true)
            return http(originalRequest);

        }

        else {
            // console.log("SEEMS LIKE THE TOKEN EXPIRED")
            if (isRefreshTokenExpired() && isAccessTokenExpired()) {
                // console.log("BOTH TOKENS EXPIRED")
                localStorage.removeItem("access_token")
                localStorage.removeItem("refresh_token")
            }
        }
    }
    return error
});

export default http;