const themes = {
    light: {
        bg: {
            primary: 'bg-[#071b1f]',
            body: 'bg-[#f8f8fb]',
            card: 'bg-slate-100',
            skeleton: 'animate-pulse bg-gray-300 rounded-full dark:bg-gray-700 w-full whitespace-pre'
        },
        text: {
            primary: 'text-slate-800',
            light: 'text-slate-300'
        },
        border: 'border-black',
        button: {
            primary: 'bg-light:primary text-white',
            secondary: 'bg-light:secondary text-black',
        },
        // Add more properties as needed
    },
    light_old: {
        bg: {
            primary: 'bg-[#415c9aef]',
            body: 'bg-[#f8f8fb]',
            card: 'bg-[#f8f8fc]'
        },
        text: {
            primary: 'text-black',
        },
        border: 'border-[#071b1f]',
        button: {
            primary: 'bg-light:primary text-white',
            secondary: 'bg-light:secondary text-black',
        },
        // Add more properties as needed
    },
    dark: {
        bg: 'bg-dark:primary',
        card: 'bg-[#f8f8fc]',
        text: 'text-white',
        border: 'border-gray-600',
        button: {
            primary: 'bg-dark:primary text-white',
            secondary: 'bg-dark:secondary text-black',
        },
        // Add more properties as needed
    },
    // Add new themes here
};

// Export the themes
export default themes;